import { createSlice } from "@reduxjs/toolkit";

const keySlice = createSlice({
  name: "key",
  initialState: "Past Contact",
  reducers: {
    setKey: (state, action) => {
      console.log("key set", action.payload);
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => state);
  },
});

export const { setKey } = keySlice.actions;
export default keySlice.reducer;
