import { initializeApp, getApp, getApps } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { firebaseSecondaryConfig } from '../../config/config';


// Initialize the secondary Firebase app
let secondaryApp;
if (!getApps().some(app => app.name === 'secondary')) {
  secondaryApp = initializeApp(firebaseSecondaryConfig, 'secondary');
} else {
  secondaryApp = getApp('secondary');
}

// Get the database instance for the secondary app
export const secondaryDatabase = getDatabase(secondaryApp);
