import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: "1",
  reducers: {
    setAlert: (state, action) => {
      console.log("Alert set", action.payload);
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => state);
  },
});

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;
