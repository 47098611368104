// src/LoadingCircle.js
import React from 'react';
import './loading.css';

export const Loading = () => {
  return (
    <div className="loading-circle"></div>
  );
}

