import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {
  fetchNmsLogsDataFromAPI,
  fetchAllNmsLogsDataFromAPI,
} from "../../api/gcp_alert";
import { EMAIL, PASSWORD } from "../../config/config";

export const logsAdapter = createEntityAdapter({
  selectId: (entity) => entity.job_id,
});

const initialState = logsAdapter.getInitialState({
  status: "idle",
});

const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    logsReceived(state, action) {
      console.log("logs action:", action);
      console.log("logs payload", action.payload);
    },
    logsData(state, action) {
      console.log("GP --state---", state.logs);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        logsAdapter.setAll(state, action.payload);
        state.status = "fulfilled";
        console.log("NMS LOGs fetch action executed");
        console.log("Nms logs current status", action.requestStatus);
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export const fetchLogs = createAsyncThunk(
  "tickets/fetchLogs",
  async (filters) => {
    let logsData;
    let apiData;
    try {
      if (filters[1] === "all") {
        apiData = await fetchAllNmsLogsDataFromAPI(EMAIL, PASSWORD, filters);
      } else {
        apiData = await fetchNmsLogsDataFromAPI(EMAIL, PASSWORD, filters);
      }
      console.log("logs call made");

      if (apiData.length > 0) {
        logsData = apiData;
      } else {
        logsData = [];
      }
    } catch (error) {
      console.error(error);
    }
    return logsData;
  }
);

export const { selectAll: select } = logsSlice.actions;
export const { logsReceived, logsKey, logsData } = logsSlice.actions;
export default logsSlice.reducer;
