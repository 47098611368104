import React, { useState } from "react";
import "./NewAlertPage.css";
import {
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Box,
  Text,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { images } from "../../res/images";
import EditIcon from "@mui/icons-material/Edit";

const scopes = [
  { label: "CVUS0250", value: 1 },
  { label: "CVUS0251", value: 2 },
];
const priority = [
  { label: "P1 - Critical", value: 1 },
  { label: "P2 - Impaired", value: 2 },
  { label: "P3 - Minor", value: 3 },
];
const action = [
  { label: "Dispatch", value: 1 },
  { label: "Research", value: 2 },
];

function NewAlertPage() {
  const [text, setText] = React.useState("SWY143");
  const [isEditing, setIsEditing] = React.useState(false);
  const [isContentEdited, setIsContentEdited] = React.useState(false);
  const [isButtonOrangeClicked, setIsButtonOrangeClicked] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  const [selectedScopeLabel, setSelectedScopeLabel] = useState("");
  const [messageSubject, setMessageSubject] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [alertName, setAlertName] = useState("");
  const [messageBody, setMessageBody] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    alertName: false,
    selectedScope: false,
    selectedPriority: false,
    selectedAction: false,
    messageSubject: false,
    messageBody: false,
  });

  const validateForm = () => {
    const errors = {
      alertName: !alertName,
      selectedScope: !selectedScope,
      selectedPriority: !selectedPriority,
      selectedAction: !selectedAction,
      messageSubject: !messageSubject,
      messageBody: !messageBody,
    };
    setFieldErrors(errors);

    return Object.values(errors).every((error) => !error);
  };

  const handleScopeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedScope(selectedValue);
    setSelectedScopeLabel(
      scopes.find((scope) => scope.value === selectedValue).label
    );
    handleContentChange();
  };

  const handlePriorityChange = (event) => {
    setSelectedPriority(event.target.value);
    handleContentChange();
  };

  const handleActionChange = (event) => {
    setSelectedAction(event.target.value);
    handleContentChange();
  };

  const handleContentChange = () => {
    setIsContentEdited(true);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  const handleButtonOrangeClick = () => {
    setIsButtonOrangeClicked(true);
    setIsFormSubmitted(true); // Mark the form as submitted for error display
    if (!validateForm()) {
      // Show an alert or handle validation failure
      alert(" all the fields are required.");
      setIsButtonOrangeClicked(false);
    }
  };

  return (
    <Container className="content_wrapper">
      <Box className="main_container">
        <Grid item lg={8} xs={12} sm={12} md={8}>
          <span className="text_createAltert">Create new alert</span>
          <div className="text_2_container">
            <span className="text_2_prefix">
              Enter the details of your alert. These details
            </span>
            <span className="text_2_suffix">will be sent as the action.</span>
          </div>
          <Grid className="form_container">
            {/*------------------- ALERT NAME ----------------------- */}

            <Grid>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "0.5rem",
                  fontFamily: "Poppins,sans-serif",
                  color: "rgba(137, 137, 137, 1)",
                  fontWeight: "500",
                  fontSize: "1.2rem",
                  lineHeight: "1.8rem",
                  letterSpacing: "1%",
                }}
              >
                ALERT NAME
              </InputLabel>
              <TextField
                placeholder="Enter your alert name"
                variant="outlined"
                onChange={(e) => {
                  setAlertName(e.target.value);
                  handleContentChange();
                }}
                value={alertName}
                error={isFormSubmitted && !alertName}
                helperText={
                  isFormSubmitted && !alertName ? "Alert name is required." : ""
                }
                fullWidth
                InputProps={{
                  inputProps: {
                    style: {
                      fontSize: "1.4rem",
                      fontWeight: "400",
                    },
                  },
                }}
                sx={{
                  background:
                    "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                  border: "1px solid rgba(222, 222, 222, 1)",
                  borderRadius: "0.8rem",
                  fontFamily: "Poppins,sans-serif",
                }}
              />
            </Grid>

            {/*------------------- SCOPE ----------------------- */}

            <div className="sub_container">
              <Grid item xs={12} lg={4} className="custom_select">
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "0.5rem",
                    fontFamily: "Poppins,sans-serif",
                    color: "rgba(137, 137, 137, 1)",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    lineHeight: "1.8rem",
                    letterSpacing: "1%",
                  }}
                >
                  SCOPE
                </InputLabel>
                <TextField
                  select
                  label={selectedScope ? "" : "Select your scope"}
                  variant="outlined"
                  onChange={handleScopeChange}
                  value={selectedScope}
                  error={isFormSubmitted && fieldErrors.selectedScope}
                  helperText={
                    isFormSubmitted && fieldErrors.selectedScope
                      ? "scope is required."
                      : ""
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontSize: "1.4rem",
                      fontWeight: "400",
                      color: "rgba(137, 137, 137, 1)",
                    },
                  }}
                  InputProps={{
                    disableAnimation: true, // Disable label animation
                    style: {
                      borderBottom: "none",
                    },
                  }}
                  sx={{
                    background:
                      "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                    border: "1px solid rgba(222, 222, 222, 1)",
                    borderRadius: "0.8rem",
                    fontFamily: "Poppins,sans-serif",
                  }}
                >
                  {scopes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/*------------------- ALERT ID ----------------------- */}

              <Grid item xs={12} lg={4} className="custom_select_sub">
                <InputLabel
                  className="space_container"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "0.5rem",
                    fontFamily: "Poppins,sans-serif",
                    color: "rgba(137, 137, 137, 1)",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    lineHeight: "1.8rem",
                    letterSpacing: "1%",
                  }}
                >
                  ALERT ID
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder="id"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  disabled={!isEditing}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isEditing ? (
                          <IconButton onClick={handleSave}>
                            <EditIcon />
                          </IconButton>
                        ) : (
                          <IconButton onClick={handleEdit}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    background:
                      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #EBEBEB, #EBEBEB)",
                    border: "1px solid rgba(235, 235, 235, 1)",
                    borderRadius: "0.8rem",
                    fontFamily: "Poppins,sans-serif",
                  }}
                />
              </Grid>
            </div>

            {/*------------------- PRIORITY ----------------------- */}

            <div className="sub_container">
              <Grid item xs={12} lg={4} className="custom_select">
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "0.5rem",
                    fontFamily: "Poppins,sans-serif",
                    color: "rgba(137, 137, 137, 1)",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    lineHeight: "1.8rem",
                    letterSpacing: "1%",
                  }}
                >
                  PRIORITY
                </InputLabel>
                <TextField
                  select
                  label={selectedPriority ? "" : "Select your priority"}
                  variant="outlined"
                  onChange={(e) => {
                    setSelectedPriority(e.target.value);
                    handleContentChange();
                  }}
                  value={selectedPriority}
                  error={isFormSubmitted && fieldErrors.selectedPriority}
                  helperText={
                    isFormSubmitted && fieldErrors.selectedPriority
                      ? "priority is required."
                      : ""
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontSize: "1.4rem",
                      fontWeight: "400",
                      color: "rgba(137, 137, 137, 1)",
                    },
                  }}
                  InputProps={{
                    disableAnimation: true, // Disable label animation
                    style: {
                      borderBottom: "none",
                    },
                  }}
                  sx={{
                    background:
                      "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                    border: "1px solid rgba(222, 222, 222, 1)",
                    borderRadius: "0.8rem",
                    fontFamily: "Poppins,sans-serif",
                  }}
                >
                  {priority.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/*------------------- ACTION ----------------------- */}

              <Grid item xs={12} lg={4} className="custom_select_sub">
                <InputLabel
                  className="space_container"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "0.5rem",
                    fontFamily: "Poppins,sans-serif",
                    color: "rgba(137, 137, 137, 1)",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    lineHeight: "1.8rem",
                    letterSpacing: "1%",
                  }}
                >
                  ACTION
                </InputLabel>
                <TextField
                  select
                  label={selectedAction ? "" : "Select your action"}
                  variant="outlined"
                  onChange={(e) => {
                    setSelectedAction(e.target.value);
                    handleContentChange();
                  }}
                  value={selectedAction}
                  error={isFormSubmitted && fieldErrors.selectedAction}
                  helperText={
                    isFormSubmitted && fieldErrors.selectedAction
                      ? "action is required."
                      : ""
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontSize: "1.4rem",
                      fontWeight: "400",
                      color: "rgba(137, 137, 137, 1)",
                    },
                  }}
                  InputProps={{
                    disableAnimation: true, // Disable label animation
                    style: {
                      borderBottom: "none",
                    },
                  }}
                  sx={{
                    background:
                      "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                    border: "1px solid rgba(222, 222, 222, 1)",
                    borderRadius: "0.8rem",
                    fontFamily: "Poppins,sans-serif",
                  }}
                >
                  {action.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </div>

            {/*------------------- MESSAGE SUBJECT ----------------------- */}

            <div style={{ marginTop: "2rem" }}>
              <Grid>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "0.5rem",
                    fontFamily: "Poppins,sans-serif",
                    color: "rgba(137, 137, 137, 1)",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    lineHeight: "1.8rem",
                    letterSpacing: "1%",
                  }}
                >
                  MESSAGE SUBJECT
                </InputLabel>
                <TextField
                  placeholder="{Message Subject}"
                  variant="outlined"
                  onChange={(e) => {
                    setMessageSubject(e.target.value);
                    handleContentChange();
                  }}
                  value={messageSubject}
                  error={isFormSubmitted && fieldErrors.messageSubject}
                  helperText={
                    isFormSubmitted && fieldErrors.messageSubject
                      ? "Message subject is required."
                      : ""
                  }
                  fullWidth
                  InputProps={{
                    inputProps: {
                      style: {
                        fontSize: "1.4rem",
                        fontWeight: "400",
                      },
                    },
                  }}
                  sx={{
                    background:
                      "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                    border: "1px solid rgba(222, 222, 222, 1)",
                    borderRadius: "0.8rem",
                    fontFamily: "Poppins,sans-serif",
                  }}
                />
                <div style={{ marginTop: "1rem" }}>
                  {/* Fixed message displaying selected scope and message */}
                  {selectedScopeLabel && messageSubject && (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "1rem",
                        fontFamily: "Poppins,sans-serif",
                        fontSize: "1.2rem",
                        fontWeight: "400",
                        lineHeight: "1.8rem",
                      }}
                    >
                      <span style={{ color: "rgba(137, 137, 137, 1)" }}>
                        {selectedScopeLabel}:
                      </span>
                      <span style={{ color: "rgba(243, 110, 33, 1)" }}>
                        &#123;{messageSubject}&#125;
                      </span>
                      <span style={{ color: "rgba(137, 137, 137, 1)" }}>
                        Dispatch Fred Meyer Store #123 Seattle WA
                      </span>
                    </div>
                  )}
                </div>
              </Grid>
            </div>

            {/*------------------- MESSAGE Body ----------------------- */}

            <div style={{ marginTop: "2rem" }}>
              <Grid>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: "0.5rem",
                    fontFamily: "Poppins,sans-serif",
                    color: "rgba(137, 137, 137, 1)",
                    fontWeight: "500",
                    fontSize: "1.2rem",
                    lineHeight: "1.8rem",
                    letterSpacing: "1%",
                  }}
                >
                  MESSAGE BODY
                </InputLabel>
                <TextField
                  placeholder="Enter your message body"
                  variant="outlined"
                  onChange={(e) => {
                    setMessageBody(e.target.value);
                    handleContentChange();
                  }}
                  value={messageBody}
                  error={isFormSubmitted && fieldErrors.messageBody}
                  helperText={
                    isFormSubmitted && fieldErrors.messageBody
                      ? "Message Body is required."
                      : ""
                  }
                  fullWidth
                  InputProps={{
                    inputProps: {
                      style: {
                        fontSize: "1.4rem",
                        fontWeight: "400",
                        paddingBottom: "7rem",
                        display: "flex",
                        flexWrap: "wrap",
                      },
                    },
                  }}
                  sx={{
                    background:
                      "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                    border: "1px solid rgba(222, 222, 222, 1)",
                    borderRadius: "0.8rem",
                    fontFamily: "Poppins,sans-serif",
                  }}
                />
              </Grid>
            </div>

            {/*------------------- FINISH EDITTING ----------------------- */}

            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {isContentEdited ? (
                <img
                  id="buttonOrangeImage"
                  src={images.buttonOrange}
                  alt="Button Orange"
                  onClick={handleButtonOrangeClick}
                />
              ) : (
                <img src={images.buttonGray} alt="Button gray" />
              )}
            </div>

            {/*------------------- CANCEL AND SAVE BUTTON ----------------------- */}

            <div
              style={{
                display: "flex",
                marginTop: "5rem",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={images.buttonCancel}
                alt="Button Cancel"
                style={{ marginRight: "10px" }}
              />
              {isButtonOrangeClicked ? (
                <img src={images.buttonSaveOrange} alt="Button Save Orange" />
              ) : (
                <img src={images.buttonSaveGray} alt="Button save Gray" />
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default NewAlertPage;
