export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL:process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const firebaseSecondaryConfig = {
  apiKey: process.env.REACT_APP_SECONDARY_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_SECONDARY_FIREBASE_AUTH_DOMAIN,
  databaseURL:process.env.REACT_APP_SECONDARY_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_SECONDARY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_SECONDARY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SECONDARY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_SECONDARY_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_SECONDARY_FIREBASE_MEASUREMENT_ID
}  

export const BASEURL = process.env.REACT_APP_BACKEND_URL
export const EMAIL = process.env.REACT_APP_BACKEND_USER_ID
export const PASSWORD = process.env.REACT_APP_BACKEND_USER_PASSWORD