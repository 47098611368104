import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { fetchFiltersFromAPI } from "../../api/gcp_alert";
import { EMAIL, PASSWORD } from "../../config/config";

export const filtersAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
});

const initialState = filtersAdapter.getInitialState({
  status: "idle",
});

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    filtersReceived(state, action) {
      console.log("filters action:", action);
      console.log("filters payload", action.payload);
    },
    filtersData(state, action) {
      console.log("GP --state---", state.logs);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilters.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        filtersAdapter.setAll(state, action.payload);
        state.status = "fulfilled";
        console.log("filters fetch");
        console.log("filters call status", action.requestStatus);
      })
      .addCase(fetchFilters.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export const fetchFilters = createAsyncThunk(
  "tickets/fetchfilters",
  async () => {
    let filterData;
    try {
      const apiData = await fetchFiltersFromAPI(EMAIL, PASSWORD);
      if (apiData.length > 0) {
        filterData = apiData;
      } else {
        filterData = [];
      }
    } catch (error) {
      console.error(error);
    }
    return filterData;
  }
);

export const { selectAll: select } = filtersSlice.actions;
export const { filtersReceived, filtersKey, filterData } = filtersSlice.actions;
export default filtersSlice.reducer;
