import { createSlice } from '@reduxjs/toolkit';

const today = new Date();
const lastWeek = new Date(today);
lastWeek.setDate(today.getDate() - 7);
function formatDate(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

const rangeSlice = createSlice({
    name: 'range',
    initialState: formatDate(lastWeek),
    reducers: {
      setNmsRange: (state, action) => {
        console.log('range set',action.payload)
        return action.payload
      },
    },
    extraReducers: (builder) => {
        builder
          .addDefaultCase((state, action) => state);
      },
  });

export const {setNmsRange} = rangeSlice.actions
export default  rangeSlice.reducer;