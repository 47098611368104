import axios from "axios";
import { Buffer } from "buffer";
import { BASEURL } from "../config/config";

export const fetchTicketsDataFromAPI = async (email, password, filters) => {
  const filter =
    "?start=" +
    filters[0] +
    "&concept=" +
    filters[1] +
    "&specialty=" +
    filters[2];
  const testapi = BASEURL + "/reporting/v1/tickets/" + filter;
  const base64encodedData = Buffer.from(`${email}:${password}`).toString(
    "base64"
  );
  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Basic ${base64encodedData}`,
    Authorization: `Basic ZGFzaHFhQHN3eWZ0c3RvcmUuY29tOmZCaTl2SU5jMHZocVBnUXp1cTJJTlM1ZzJJSDI=`,
  };

  try {
    const response = await axios.get(testapi, { headers });
    console.log("APi call Made");
    return Object.values(response.data.data);
  } catch (error) {
    console.error("Error fetching tickets data:", error);
    throw error;
  }
};

export const fetchNmsLogsDataFromAPI = async (email, password, filters) => {
  const filter = "?start=" + filters[0];
  console.log("store call", filters[1]);
  const testapi = BASEURL + "/reporting/v1/nms_logs/" + filter;
  const base64encodedData = Buffer.from(`${email}:${password}`).toString(
    "base64"
  );
  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Basic ${base64encodedData}`,
    Authorization: `Basic ZGFzaHFhQHN3eWZ0c3RvcmUuY29tOmZCaTl2SU5jMHZocVBnUXp1cTJJTlM1ZzJJSDI=`,
  };
  try {
    const response = await axios.get(testapi, { headers });
    console.log("NMS Logs AP call Made");
    return Object.values(response.data.data);
  } catch (error) {
    console.error("Error fetching tickets data:", error);
    throw error;
  }
};

export const fetchAllNmsLogsDataFromAPI = async (email, password, filters) => {
  const filter = "?start=" + filters[0];
  const testapi = BASEURL + "/reporting/v1/nms_logs/all/" + filter;
  // const testapi = BASEURL + "/reporting/v1/nms_logs/all/" + filter;
  const base64encodedData = Buffer.from(`${email}:${password}`).toString(
    "base64"
  );
  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Basic ${base64encodedData}`,
    Authorization: `Basic ZGFzaHFhQHN3eWZ0c3RvcmUuY29tOmZCaTl2SU5jMHZocVBnUXp1cTJJTlM1ZzJJSDI=`,
  };
  try {
    const response = await axios.get(testapi, { headers });
    console.log("NMS Logs AP call Made");
    return Object.values(response.data.data);
  } catch (error) {
    console.error("Error fetching tickets data:", error);
    throw error;
  }
};

export const fetchFiltersFromAPI = async (email, password) => {
  const testapi =
    BASEURL +
    "/reporting/v1/dash_filters/?role=location_partner&account=10950&filters=stores";
  const base64encodedData = Buffer.from(`${email}:${password}`).toString(
    "base64"
  );
  const headers = {
    "Content-Type": "application/json",
    // Authorization: `Basic ${base64encodedData}`,
    Authorization: `Basic ZGFzaHFhQHN3eWZ0c3RvcmUuY29tOmZCaTl2SU5jMHZocVBnUXp1cTJJTlM1ZzJJSDI=`,
  };
  try {
    const response = await axios.get(testapi, { headers });
    console.log("filters call Made");
    return Object.values(response.data.data);
  } catch (error) {
    console.error("Error filters data:", error);
    throw error;
  }
};
