import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

function ConfirmationModal({ open, onClose, onConfirm, switchState, alert }) {
  return (
    <div>
      <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Enable/Disable Switch
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to {switchState ? "disable" : "enable"} the Alert {alert}?
        </Typography>
        <Button onClick={onConfirm}>Yes</Button>
        <Button onClick={onClose}>Cancel</Button>
      </Box>
    </Modal>
    </div>
  )
}

export default ConfirmationModal
