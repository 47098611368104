import { ref, get, child, update } from "firebase/database";
import { db } from "../services/auth/firebaseapp";
import { secondaryDatabase } from "../services/auth/secondaryFirebase";
export const lastRun = () => {
  const dbRef = ref(db, "nms/master/");
  get(child(dbRef, `last_run/`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log("FB", snapshot.val());
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getAlert = (index) => {
  const alertRef = ref(
    secondaryDatabase,
    "nms/alerts/" + index + "/conditions"
  );

  // Returning the promise returned by the `get` function
  return get(alertRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        const conditionsData = snapshot.val();
        return conditionsData;
      } else {
        console.log("No data available for alertId with index", index);
        return "No data Found";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return "Error while fetching data";
    });
};

export const updateEnabled = (index, newEnabledValue) => {
  console.log("call", index, newEnabledValue);
  const alertRef = ref(secondaryDatabase, "nms/alerts/" + index);

  const updates = {};
  updates["enabled"] = newEnabledValue;

  // Perform the update
  return update(alertRef, updates)
    .then(() => {
      console.log("Successfully updated alert's enabled status.");
      return "Update successful";
    })
    .catch((error) => {
      console.error("Error updating enabled status:", error);
      return "Error while updating enabled status";
    });
};

export const getEnabledValue = (index) => {
  const alertRef = ref(secondaryDatabase, "nms/alerts/" + index + "/enabled");

  // Perform the get operation
  return get(alertRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        const enabledValue = snapshot.val();
        console.log("Fetched enabled value:", enabledValue);
        return enabledValue;
      } else {
        console.log("No data available for alertId with index", index);
        return "No data Found";
      }
    })
    .catch((error) => {
      console.error("Error fetching enabled value:", error);
      return "Error while fetching enabled value";
    });
};
// getEnabledValue(1);

export const getConcepts = () => {
  const dbRef = ref(secondaryDatabase, "nms/config/");
  return get(child(dbRef, "concepts/"))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log("FB", snapshot.val());
        const conceptData = snapshot.val();
        return conceptData;
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getTaskScheduler = () => {
  const taskSchedulerRef = ref(secondaryDatabase, "nms/alerts");

  // Returning the promise returned by the `get` function
  return get(taskSchedulerRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available for task_scheduler");
        return "No data Found";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return "Error while fetching data";
    });
};

export const getAlertRules = () => {
  const taskSchedulerRef = ref(secondaryDatabase, "nms/alerts");
  return get(taskSchedulerRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available for task_scheduler");
        return "No data Found";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return "Error while fetching data";
    });
};
