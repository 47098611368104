import { createSlice } from '@reduxjs/toolkit';

const today = new Date();
const last30Days = new Date(today);
last30Days.setDate(today.getDate() - 30);
function formatDate(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

const timeSlice = createSlice({
    name: 'time',
    initialState: formatDate(last30Days),
    reducers: {
      setTimeRange: (state, action) => {
        console.log('time set',action.payload)
        return action.payload
      },
    },
    extraReducers: (builder) => {
        builder
          .addDefaultCase((state, action) => state);
      },
  });

export const {setTimeRange} = timeSlice.actions
export default  timeSlice.reducer;

