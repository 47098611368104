import React, { useState, useEffect, useMemo, useCallback } from "react";
import { images } from "../../res/images";
import "./CreateTable.css";
import ReactPaginate from "react-paginate";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import Pagination from "@mui/material/Pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
} from "@mui/material";

import "../../services/store/ticketsSlice";
import { useSelector, useDispatch } from "react-redux";
import { setKey } from "../../services/store/keySlice";
import { Loading } from "../../animation/Loading";

function CreateTable() {
  const groupedTickets = useSelector((state) => state.tickets);
  const status = useSelector((state) => state.tickets.status);
  const key = useSelector((state) => state.key);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false); // New state for handling empty data

  useEffect(() => {
    if (status === "fulfilled") {
      if (groupedTickets.ids.includes(key)) {
        const data = groupedTickets.entities[key].data;
        if (data.length > 0) {
          setSelectedData(data);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
      } else if (groupedTickets.ids.length > 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(true);
      }
      setIsLoaded(true);
    }
  }, [status, key, groupedTickets]);

  const staticRowData = {
    CREATED_AT: "Sep 12 2",
    TICKET_ID: "413831",
    ISSUE_TASK: "Error While Initializing",
    specialty: "Research",
    PRIORITY: "2",
    tags: [images.research, images.impaired], // Example static tags
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
  };

  const getspecialtyAndPriorityText = (specialty, priority) => {
    let specialtyText = "";
    let priorityText = "";
    let priorityClass = "";
    let specialtyClass = "";

    switch (specialty) {
      case "Dispatch":
        specialtyText = "Dispatch";
        specialtyClass = "Dispatch-tag";
        break;
      case "Research":
        specialtyText = "Research";
        specialtyClass = "Research-tag";
        break;
      default:
        specialtyText = "";
        break;
    }

    switch (priority) {
      case "1":
        priorityText = "P1 - Critical";
        priorityClass = "critical-tag";
        break;
      case "2":
        priorityText = "P2 - Impaired";
        priorityClass = "impaired-tag";
        break;
      case "3":
        priorityText = "P3 - Minor";
        priorityClass = "minor-tag";
        break;
      default:
        priorityText = "Unknown";
        priorityClass = "";
        break;
    }

    return { specialtyText, priorityText, priorityClass, specialtyClass };
  };

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5; // Set the number of items per page

  const startIndex = pageNumber * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, selectedData.length);

  const paginatedData = selectedData.slice(startIndex, endIndex);

  const pageCount = Math.ceil(selectedData.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const convertUnixTimestampToUTC = (timestamp) => {
    const date = new Date(timestamp);
    // return date.toUTCString();
    return date.toUTCString().replace(/,/, " -");
  };

  // for csv file

  const convertToCSV = (data) => {
    const csvRows = [];
    // Define headers
    const headers = [
      "TIMESTAMP",
      "TICKET ID",
      "ESTATION NAME",
      "ALERT",
      "TAGS",
    ];
    csvRows.push(headers.join(","));

    // Process each data item
    data.forEach((post) => {
      const tagsText = Array.isArray(post.tags)
        ? post.tags.join(" | ") // Join tags with " | "
        : [
            getspecialtyAndPriorityText(post.specialty, post.priority)
              .specialtyText,
            getspecialtyAndPriorityText(post.specialty, post.priority)
              .priorityText,
          ]
            .filter(Boolean)
            .join(" | "); // Join with " | " if any text is available

      const row = [
        convertUnixTimestampToUTC(post.created_time_local), // Full timestamp
        post.ticket_id,
        post.estation_name === null ? "Null" : post.estation_name, // Handle null values
        post.subject.slice(0, 60), // Truncate subject if necessary

        tagsText,
      ];

      console.log("Row Data:", row);

      // Add row to CSV
      csvRows.push(row.join(","));
    });

    // Join rows with newline
    return csvRows.join("\n");
  };

  const handleExport = () => {
    const csvData = convertToCSV(selectedData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", "tickets.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {isLoaded ? (
        !isEmpty ? (
          selectedData.length > 0 ? (
            <>
              <TableContainer
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
                component={Paper}
                className="table-container"
              >
                <Table className="table">
                  <TableHead>
                    <TableRow className="tableHeading">
                      <TableCell>TIMESTAMP</TableCell>
                      <TableCell>TICKET ID</TableCell>
                      <TableCell>ESTATION NAME</TableCell>
                      <TableCell>ALERT</TableCell>
                      <TableCell>TAGS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="tablebody">
                    {[...paginatedData].map((post, index) => {
                      return (
                        <TableRow
                          key={post.ticket_id}
                          className={index % 2 === 0 ? "grayCell" : ""}
                        >
                          <TableCell id="tableCellTime">
                            {convertUnixTimestampToUTC(
                              post.created_time_local * 1000
                            )}
                          </TableCell>
                          <TableCell id="tableCellTicketId">
                            {post.ticket_id}
                          </TableCell>
                          <TableCell id="tableCellName">
                            {post.estation_name === null
                              ? "Null"
                              : post.estation_name}
                          </TableCell>
                          <TableCell className="table-cell-left" id="tableCell">
                            {post.subject.slice(0, 60)}
                          </TableCell>

                          <TableCell className="text-cell">
                            <div className="text-container">
                              {Array.isArray(post.tags) ? (
                                post.tags.join(" | ")
                              ) : (
                                <>
                                  {getspecialtyAndPriorityText(
                                    post.specialty,
                                    post.priority
                                  ).specialtyText && (
                                    <div
                                      className={
                                        getspecialtyAndPriorityText(
                                          post.specialty,
                                          post.priority
                                        ).specialtyClass
                                      }
                                    >
                                      {/* specialty:{" "} */}
                                      {
                                        getspecialtyAndPriorityText(
                                          post.specialty,
                                          post.priority
                                        ).specialtyText
                                      }
                                    </div>
                                  )}
                                  {getspecialtyAndPriorityText(
                                    post.specialty,
                                    post.priority
                                  ).priorityText && (
                                    <div
                                      className={
                                        getspecialtyAndPriorityText(
                                          post.specialty,
                                          post.priority
                                        ).priorityClass
                                      }
                                    >
                                      {/* Priority:{" "} */}
                                      {
                                        getspecialtyAndPriorityText(
                                          post.specialty,
                                          post.priority
                                        ).priorityText
                                      }
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <section className="pagination-section">
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handleChangePage}
                    shape="rounded"
                    containerclassname={"pagination"}
                    boundaryCount={2}
                    showFirstButton={page > 1}
                    showLastButton={page < pageCount}
                    className={"pagination__link"}
                    renderItem={(item) => {
                      switch (item.type) {
                        case "page":
                          // Only render the first, current, and last page numbers
                          if (
                            item.page === 1 ||
                            item.page === page ||
                            item.page === pageCount
                          ) {
                            return (
                              <PaginationItem
                                {...item}
                                style={{
                                  color: "rgba(243, 110, 33, 1)",
                                  fontWeight: "bold",
                                  fontSize: "1.2rem",
                                }}
                              />
                            );
                          }
                          return null;
                        case "first":
                          return page === 1 ? (
                            <PaginationItem
                              {...item}
                              onClick={() => handleChangePage(null, 1)}
                            />
                          ) : null;
                        case "last":
                          return page === pageCount ? (
                            <PaginationItem
                              {...item}
                              onClick={() => handleChangePage(null, pageCount)}
                            />
                          ) : null;
                        default:
                          return <PaginationItem {...item} />;
                      }
                    }}
                  />
                </Stack>
                <Button onClick={handleExport}>Export</Button>
              </section>
            </>
          ) : (
            <div className="center">No Data Available</div>
          )
        ) : (
          <div className="center">No data found for the selected alert</div>
        )
      ) : (
        <Loading />
      )}
    </>
  );
}

export default CreateTable;
