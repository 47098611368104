import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getAlertRules } from "../../api/alerts";

function updateAlerts(alerts) {
  const updatedAlerts = {};
  for (const key in alerts) {
    if (alerts.hasOwnProperty(key)) {
      const alert = alerts[key];
      const alert_id = alert.alert_id;
      updatedAlerts[alert_id] = { ...alert, original_key: key };
    }
  }
  return updatedAlerts;
}

const rulesAdapter = createEntityAdapter({
  selectId: (entity) => entity.original_key,
});

const initialState = rulesAdapter.getInitialState({
  status: "idle",
});

// fetching data here from firebase using getAlertrules()
export const fetchRules = createAsyncThunk(
  "rules/fetchRules",
  async (filters) => {
    let updatedData = {};
    try {
      const apiData = await getAlertRules();
      if (Object.keys(apiData).length > 0) {
        updatedData = updateAlerts(apiData);
      } else {
        console.log("no data");
        updatedData = {};
      }
    } catch (error) {
      console.error(error);
      updatedData = {};
    }
    return updatedData;
  }
);

//here checking the status of detch data
const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    rulesReceived(state, action) {
      console.log("rulesReceived action:", action);
      console.log("payload", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRules.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchRules.fulfilled, (state, action) => {
        rulesAdapter.setAll(state, action.payload);
        state.status = "fulfilled";
        console.log("fetch action executed");
        console.log("current status", action.requestStatus);
      })
      .addCase(fetchRules.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export const { selectAll: selectRules } = rulesAdapter.getSelectors(
  (state) => state.rules
);
export const { rulesReceived } = rulesSlice.actions;
export default rulesSlice.reducer;
