import { useState, useEffect } from "react";
import {  getAuth, signInWithEmailAndPassword , signInWithCustomToken ,onAuthStateChanged} from "firebase/auth";
import { useNavigate } from 'react-router-dom';


export const handleSignIn = async (token, email, password) => {
  const auth = getAuth();
    try {
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        console.log('already signed in with token:', token);
      } else {
        const result = await signInWithEmailAndPassword(auth, email, password);
        console.log('signed in with user id:',result.user.uid)
        return true
      }
    } catch (error) {
      console.error('Sign-in error:', error);
    }
  };


  export const handleSignOut = async () => {
    const auth = getAuth()
    try {
      await auth.signOut();
  
      // Example: Make an API call after successful sign-out
      const apiResponse = await fetch('https://jsonplaceholder.typicode.com/users/1', {
        method: 'GET', // For example, if logging out requires a POST request
        // Include any necessary headers or data based on your API's requirements
      });

      if (apiResponse.ok) {
        console.log('Logged out successfully from API.');
      } else {
        console.error('API request failed during logout');
      }
    } catch (error) {
      console.error('Sign-out error:', error);
    }
  };

  export const testfunction = async(token) => {
    const email = 'daksh.hitesh@swyft.com';
    const password = ''

    const customToken = token;
    const auth = getAuth();
    console.log('test function')
    const result = await signInWithCustomToken(auth, customToken)
    .then((userCredential) => {
      // User is signed in with the custom token
      
      const user = userCredential.user;
      console.log('User is signed in:', user);
    })
    .catch((error) => {
      // Handle sign-in error
      console.error('Sign-in error: with test fuunction');
    });
  }

  export const signInWithToken = async (token) => {
    const auth = getAuth();
    const apiResponse = await fetch('http://localhost:3001/verify-token', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (apiResponse.ok) {
      const apiData = await apiResponse.json();

      console.log('api data',apiData)
      console.log('API response:', apiData.customToken);
      console.log('token verifyied')

      testfunction(apiData.customToken);

      signInWithCustomToken(auth, apiData.customToken)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log('sign in using custom token')
          console.log('User is signed in:', user);
        })
        .catch((error) => {
          console.error('Sign-in error with custom token:', error);
          console.log('error while signing with custom token')
        });

        return apiData.userCredential

        } else {
          console.error('API request failed');
    }
  };

  export const useAuth = ()=> {
    
    const [user, setUser] = useState(null);
    const auth = getAuth()
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth,(user) => {
        setUser(user);
      });
  
      return unsubscribe;
    }, []);
  
    return user;
  }
 