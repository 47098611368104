import { createSlice } from '@reduxjs/toolkit';

const storeIdSlice = createSlice({
    name: 'storeId',
    initialState: 'all',
    reducers: {
      setStoreId: (state, action) => {
        return action.payload
      },
    },
    extraReducers: (builder) => {
        builder
          .addDefaultCase((state, action) => state);
      },
  });

export const {setStoreId} = storeIdSlice.actions
export default storeIdSlice.reducer;