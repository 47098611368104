import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setKey } from "../../services/store/keySlice";
import {
  LineChart,
  Legend,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Brush,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Box } from "@mui/material";
import { Loading } from "../../animation/Loading";
import "./Chart.css";

function Chart() {
  const dispatch = useDispatch();
  const [dataPoints, setDataPoints] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const key = useSelector((state) => state.key);
  const groupedTickets = useSelector((state) => state.tickets);
  const status = useSelector((state) => state.tickets.status);
  const time = useSelector((state) => state.time);
  const vendor = useSelector((state) => state.vendor);
  const speciality = useSelector((state) => state.speciality);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    console.log("default issue task", key);
    console.log("filters date", time);
    console.log("filters vendor", vendor);
    console.log("filters speciality", speciality);
  }, [time]);

  useEffect(() => {
    if (status === "fulfilled") {
      if (groupedTickets.ids.indexOf(key) !== -1) {
        setGraph(groupedTickets.entities[key].data);
      } else {
        setIsEmpty(true); // Set empty state if no data is found for the selected key
      }
    } else if (status === "fulfilled" && groupedTickets.ids.length === 0) {
      setIsEmpty(true);
    }
  }, [status, key]);

  const setGraph = (jsonData) => {
    const ticketCounts = {};
    for (const ticket of jsonData) {
      const created = ticket.created_time_local;
      const createdAtDate = new Date(created * 1000);
      const year = createdAtDate.getFullYear();
      const month = String(createdAtDate.getMonth() + 1).padStart(2, "0");
      const day = String(createdAtDate.getDate()).padStart(2, "0");

      const createdAt = `${year}-${month}-${day}`;

      if (ticketCounts[createdAt]) {
        ticketCounts[createdAt]++;
      } else {
        ticketCounts[createdAt] = 1;
      }
    }

    const dateArray = Object.keys(ticketCounts).map((createdAt) => ({
      x: createdAt,
      y: ticketCounts[createdAt],
    }));

    setDataPoints(dateArray);
    setIsLoaded(true);
  };

  const legendPayload = [
    {
      id: "No. of Tickets",
      value: "No. of Tickets",
      color: "rgba(243, 110, 33, 1)",
    },
  ];

  const itemDescriptors = [{ dataKey: "y", date: "x" }];

  const TooltipContent = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Number of Tickets: ${payload[0].value}`}</p>
          <p className="intro">{`Date: ${label}`}</p>
        </div>
      );
    }

    return null;
  };

  const xTicks = ["auto"];

  return (
    <>
      <Box className="key-card">{key}</Box>
      <div className="chart-wrapper">
        <div className="chart-container">
          {isLoaded ? (
            <ResponsiveContainer width="100%" height={600}>
              <LineChart
                data={dataPoints}
                margin={{ top: 20, right: 24, bottom: 20, left: 10 }}
              >
                <Legend payload={legendPayload} />
                <CartesianGrid
                  stroke="#ededed"
                  strokeDasharray="3 3"
                  vertical={true}
                />
                <XAxis
                  dataKey="x"
                  label={{ fontSize: "12px", value: "Date" }}
                />
                <YAxis
                  dataKey="y"
                  label={{ fontSize: "12px", value: "Tickets" }}
                />
                <Tooltip content={<TooltipContent />} />
                {itemDescriptors.map((descriptor, i) => (
                  <Line
                    key={i}
                    dataKey={descriptor.dataKey}
                    stroke="rgba(243, 110, 33, 1)"
                    strokeWidth="true"
                    activeDot={true}
                    type="linear"
                    dot={true}
                    isAnimationActive={true}
                  />
                ))}
                <Brush fill="#fff" height={32} stroke="rgba(243, 110, 33, 1)">
                  <LineChart
                    data={dataPoints}
                    margin={{ top: 40, right: 24, bottom: 0, left: 10 }}
                  >
                    {itemDescriptors.map((descriptor, i) => (
                      <Line
                        key={descriptor.date}
                        dataKey={descriptor.dataKey}
                        stroke={"rgba(243, 110, 33, 1)"}
                        strokeWidth={1}
                        activeDot={true}
                        type="linear"
                        dot={false}
                        isAnimationActive={false}
                      />
                    ))}
                  </LineChart>
                </Brush>
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div className="center">
              {isEmpty ? (
                <>No data</>
              ) : (
                <>
                  <Loading />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Chart;
