import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { useSelector, useDispatch } from "react-redux";
import { images } from "../../res/images";
import CreateAlert from "../Alerts/CreateAlert";
import { setTimeRange } from "../../services/store/timeSlice";
import { setVendor, setConcepts } from "../../services/store/vendorSlice";
import { setSpeciality } from "../../services/store/specialitySlice";
import { handleSignOut } from "../../services/auth/authenticate";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../services/auth/firebaseapp";
import { styled, createTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Grid,
  Button,
  Box,
} from "@mui/material";
import AppIcons from "../../res/svg/svgAsset";
import { getConcepts } from "../../api/alerts";

function Navbar() {
  const dispatch = useDispatch();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const vendor = useSelector((state) => state.vendor.vendor);
  const speciality = useSelector((state) => state.speciality);
  const range = useSelector((state) => state.range);
  const userName = useSelector((state) => state.user);
  // const conceptsData = useSelector((state) => state.concepts);
  const conceptsData = useSelector((state) => state.vendor.concepts);

  useEffect(() => {
    console.log("Concepts data from Redux:", conceptsData);
  }, [conceptsData]);

  const today = new Date();

  const last90Days = new Date(today);
  last90Days.setDate(today.getDate() - 90);

  const last60Days = new Date(today);
  last60Days.setDate(today.getDate() - 60);

  const last30Days = new Date(today);
  last30Days.setDate(today.getDate() - 30);

  const last15Days = new Date(today);
  last15Days.setDate(today.getDate() - 15);

  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [filterDate, setFilterDate] = useState("");
  const [filterVendor, setFilterVendor] = useState("all");
  const [filterType, setFilterType] = useState("");
  const time = useSelector((state) => state.time);

  // const stores = [
  //   { value: "CEUS", label: "iStore USA" },
  //   { value: "CVUS", label: "CVS" },
  //   { value: "SJUS", label: "SJUS" },
  //   { value: "all", label: "All" },
  // ];

  const type = [
    { value: "Dispatch", label: "Dispatch" },
    { value: "Research", label: "Research" },
    { value: "Research", label: "Research" },
    { value: "all", label: "All" },
  ];

  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleDate = (date) => {
    dispatch(setTimeRange(date));
    setFilterDate(date);
    console.log("handle click", date);
  };

  // --after removing ZOOM_ ---
  const handleVendor = (vendor) => {
    const trimmedVendor = vendor.startsWith("ZOOM_") ? vendor.slice(5) : vendor;
    dispatch(setVendor(trimmedVendor));
    setFilterVendor(vendor);
    console.log("handle click", trimmedVendor);
  };

  const handleType = (type) => {
    dispatch(setSpeciality(type));
    setFilterType(type);
    console.log("handle click", type);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    setFilterDate(time);
    setFilterType(speciality);
    setFilterVendor(vendor);
  }, []);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const date = [
    { value: formatDate(last90Days), label: "Last 90 Days" },
    { value: formatDate(last60Days), label: "Last 60 Days" },
    { value: formatDate(last30Days), label: "Last 30 Days" },
    { value: formatDate(last15Days), label: "Last 15 Days" },
    { value: formatDate(lastWeek), label: "Last Week" },
    { value: formatDate(yesterday), label: "Yesterday" },
  ];

  const combinedConcepts = [
    { key: "All", value: "all" },
    ...Object.keys(conceptsData).map((key) => ({
      key,
      value: conceptsData[key],
    })),
  ];

  const CustomButton = styled(Button)(({ theme }) => ({
    fontSize: "15px",
    padding: "10px 20px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    textTransform: "none",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    margin: "5px",
    borderRadius: "4px",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  }));
  return (
    <div>
      <Toolbar>
        <Typography variant="h6">
          <img src={AppIcons.Logo} alt="Logo" />
        </Typography>

        <TextField
          className="field_1"
          select
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "1.4rem",
              fontWeight: "400",
              color: "rgba(137, 137, 137, 1)",
            },
          }}
          InputProps={{
            disableanimation: "true", // Disable label animation
            style: {
              borderBottom: "none",
            },
          }}
          sx={{
            background:
              "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
            border: "1px solid rgba(222, 222, 222, 1)",
            borderRadius: "0.8rem",
            fontFamily: "Poppins,sans-serif",
            width: {
              md: "50%", // For medium screens (min-width: 961px)
              lg: "60%",
            },
            marginLeft: "2rem",
            marginBottom: {
              xs: "1rem",
              sm: "1rem",
            },
            marginTop: {
              xs: "1rem",
              sm: "1rem",
            },
          }}
          value={filterVendor}
          onChange={(e) => handleVendor(e.target.value)}
        >
          {combinedConcepts.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              {option.key}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "1.4rem",
              fontWeight: "400",
              color: "rgba(137, 137, 137, 1)",
            },
          }}
          InputProps={{
            disableanimation: "true", // Disable label animation
            style: {
              borderBottom: "none",
            },
          }}
          sx={{
            background:
              "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
            border: "1px solid rgba(222, 222, 222, 1)",
            borderRadius: "0.8rem",
            fontFamily: "Poppins,sans-serif",
            width: "50%",
            marginLeft: "2rem",
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
          value={filterDate}
          onChange={(e) => handleDate(e.target.value)}
        >
          {date.map((option) => (
            <MenuItem
              defaultValue="Last 30 Days"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          variant="outlined"
          //   onChange={handleScopeChange}
          fullWidth
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "1.4rem",
              fontWeight: "400",
              color: "rgba(137, 137, 137, 1)",
            },
          }}
          InputProps={{
            disableanimation: "true", // Disable label animation
            style: {
              borderBottom: "none",
            },
          }}
          sx={{
            background:
              "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
            border: "1px solid rgba(222, 222, 222, 1)",
            borderRadius: "0.8rem",
            fontFamily: "Poppins,sans-serif",
            width: "30rem",
            marginLeft: "2rem",
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
          value={filterType}
          onChange={(e) => handleType(e.target.value)}
        >
          {type.map((option) => (
            <MenuItem defaultValue="" key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Box
          sx={{
            width: "7rem",
            marginLeft: "2rem",
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
        ></Box>
        <Grid
          sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginLeft: "35rem",
              paddingRight: "2rem",
              whiteSpace: "nowrap",
            }}
          >
            <CustomButton onClick={() => handleSignOut()}>
              Sign out
            </CustomButton>
          </div>
        </Grid>
      </Toolbar>
    </div>
  );
}

export default Navbar;
