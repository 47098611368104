import { createSlice } from '@reduxjs/toolkit';

// SPECIALITY
const specialitySlice = createSlice({
    name: 'specilaity',
    initialState: 'all',
    reducers: {
      setSpeciality: (state, action) => {
        console.log('speciality set',action.payload)
        return action.payload
      },
    },
    extraReducers: (builder) => {
        builder
          .addDefaultCase((state, action) => state);
      },
  });

export const { setSpeciality } = specialitySlice.actions
export default specialitySlice.reducer;