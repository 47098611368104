import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./nmsLogs.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import Pagination from "@mui/material/Pagination";
import { Loading } from "../../animation/Loading";
import { fetchFilters } from "../../services/store/filtersSlice";
import { MultipleSelectCheckmarks } from "../NmsLogs/multiSelect";

function NmsLogsTable() {
  const paginatedData = {};
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(false);
  const storeId = useSelector((state) => state.storeId);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const nmsLogs = useSelector((state) => state.logs);
  const status = useSelector((state) => state.logs.status);

  useEffect(() => {
    dispatch(fetchFilters());
  }, []);

  useEffect(() => {
    if (status === "idle") {
      console.log("status idle.. fetching data");
      console.log("current", status);
    }
    if (status === "fulfilled" && Object.keys(nmsLogs).length > 0) {
      setIsLoaded(true);
    } else if (status === "fulfilled" && Object.keys(nmsLogs).length === 0) {
      setIsEmpty(true);
    }
  }, [status, nmsLogs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentPageNumber(newPage);
  };

  const [currentNumber, setCurrentPageNumber] = useState(0);
  const itemsPerPage = 6;

  const startIndex = currentNumber * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, nmsLogs.ids.length);
  const keys = nmsLogs.ids.slice(startIndex, endIndex);
  keys.forEach((key) => {
    paginatedData[key] = nmsLogs.entities[key];
  });
  const pageCount = Math.ceil(nmsLogs.ids.length / itemsPerPage);

  const convertUnixTimestampToUTC = (timestamp) => {
    const date = new Date(timestamp);
    return date.toUTCString();
  };

  return (
    <>
      <MultipleSelectCheckmarks />
      <>
        {isLoaded ? (
          <>
            <TableContainer
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
              }}
              component={Paper}
              className="table-container"
            >
              <Table className="table">
                <TableHead>
                  <TableRow className="tableHeading">
                    <TableCell>TIMESTAMP</TableCell>
                    <TableCell>JOB ID</TableCell>
                    <TableCell>AlERT ID</TableCell>
                    <TableCell>VENDOR</TableCell>
                    <TableCell>RESULT</TableCell>
                    <TableCell>STATUS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tablebody">
                  {Object.entries(paginatedData).map((P, index) => {
                    let post = P[1];
                    return (
                      <TableRow
                        key={post.ticketId}
                        className={index % 2 === 0 ? "grayCell" : ""}
                      >
                        <TableCell id="tableCellTime">
                          {convertUnixTimestampToUTC(post.start_time)}
                        </TableCell>
                        <TableCell id="tableCellTicketId">
                          {post.job_id.slice(0, 10)}
                        </TableCell>

                        <TableCell id="tableCellName">
                          {post.alert_id === null ? "Null" : post.alert_id}
                        </TableCell>
                        <TableCell id="tableCellName">
                          {post.logical_database}
                        </TableCell>
                        <TableCell id="tableCell">
                          {" "}
                          {post.results.length > 0 ? (
                            <ul>
                              {post.results.map((result, resultIndex) => (
                                <li key={resultIndex}>
                                  Estation Name: {result.estation_name}, Alert
                                  Message: {result.alert_message}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <>No results found</>
                          )}
                        </TableCell>
                        <TableCell className="tableCell">
                          {post.status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <section className="pagination-section">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handleChangePage}
                  shape="rounded"
                  containerclassname={"pagination"}
                  boundaryCount={2}
                  showFirstButton={page > 1}
                  showLastButton={page < pageCount}
                  className={"pagination__link"}
                  renderItem={(item) => {
                    switch (item.type) {
                      case "page":
                        if (
                          item.page === 1 ||
                          item.page === page ||
                          item.page === pageCount
                        ) {
                          return (
                            <PaginationItem
                              {...item}
                              style={{
                                color: "rgba(243, 110, 33, 1)",
                                fontWeight: "bold",
                                fontSize: "1.2rem",
                              }}
                            />
                          );
                        }
                        return null;
                      case "first":
                        return page === 1 ? (
                          <PaginationItem
                            {...item}
                            onClick={() => handleChangePage(null, 1)}
                          />
                        ) : null;
                      case "last":
                        return page === pageCount ? (
                          <PaginationItem
                            {...item}
                            onClick={() => handleChangePage(null, pageCount)}
                          />
                        ) : null;
                      default:
                        return <PaginationItem {...item} />;
                    }
                  }}
                />
              </Stack>
            </section>
          </>
        ) : (
          <Loading />
        )}
      </>
    </>
  );
}

export default NmsLogsTable;
