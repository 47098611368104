import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Divider,
  InputLabel,
} from "@mui/material";

import { styled, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../services/store/userSlice";
import { images } from "../res/images";

const mainTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#F36E21",
    },
    lightorange: {
      main: "#FFF2EB",
    },
    red: {
      main: "#F41E1E",
    },
    lightred: {
      main: "#FFEDED",
    },
    yellow: {
      main: "#F4C006",
    },
    lightyellow: {
      main: "#FFF8E1",
    },
    blue: {
      main: "#50BCF9",
    },
    lightblue: {
      main: "#E7F6FF",
    },
    voilet: {
      main: "#9050F9",
    },
    lightvoilet: {
      main: "#F1E9FF",
    },
    indigo: {
      main: "#5061F9",
    },
    lightindigo: {
      main: "#E9EBFF",
    },
    gray: {
      main: "#B1B1B1",
    },
  },
});

const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: "15px",
  padding: "10px 20px",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  textTransform: "none",
  border: "none",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  margin: "5px",
  borderRadius: "4px",
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const GoogleButton = styled(Button)(({ theme }) => ({
  fontSize: "15px",
  padding: "10px 20px",
  backgroundColor: "#4285F4",
  color: "#fff",
  textTransform: "none",
  border: "none",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  margin: "5px",
  borderRadius: "4px",
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#357ae8",
  },
}));

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

const Login1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    textAlign: "center",
    padding: "10px",
    margin: "10px 0",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  }));

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: "", password: "" };

    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const signInWithEmailAndPasswordHandler = async () => {
    if (!validateForm()) {
      return console.log("ERROR: Fields Empty");
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userName = userCredential.user.displayName;
      dispatch(setUser(userName));
      navigate("/dashboard");
    } catch (error) {
      console.error(
        "Sign-in error:",
        error.message || "Unknown error occurred"
      );
    }
  };

  // const signInWithGoogleHandler = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     const userEmail = result.user.email;
  //     const userName = result.user.displayName;

  //     // Check if the email ends with "@swyft.com"
  //     if (userEmail.endsWith("@swyft.com")) {
  //       dispatch(setUser(userName));
  //       navigate("/dashboard");
  //     } else {
  //       // Show error message without navigating
  //       alert("Access denied. Please contact the administrator to get access.");
  //       await auth.signOut();
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Google sign-in error:",
  //       error.message || "Unknown error occurred"
  //     );
  //   }
  // };
  const signInWithGoogleHandler = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const userName = result.user.displayName;
      dispatch(setUser(userName));
      navigate("/dashboard");
    } catch (error) {
      console.error(
        "Google sign-in error:",
        error.message || "Unknown error occurred"
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#fff",
        borderRadius: "0px",
      }}
    >
      <Container maxWidth="xs">
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <img
            src={images.logo}
            alt="Logo"
            style={{ width: "160px", height: "70px" }}
          />
        </Box>
        <Paper
          elevation={6}
          sx={{
            padding: "40px",
            paddingTop: "50px",
            paddingBottom: "50px",
            borderRadius: "2px",
          }}
        >
          <Typography variant="h3" align="center" gutterBottom>
            Sign In
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GoogleButton onClick={signInWithGoogleHandler} fullWidth>
                Sign in with Google
              </GoogleButton>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                <Divider sx={{ flexGrow: 1 }} />
                <Typography sx={{ mx: 2, fontSize: "15px" }}>or</Typography>
                <Divider sx={{ flexGrow: 1 }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "0.5rem",
                  fontFamily: "Poppins,sans-serif",
                  color: "rgba(137, 137, 137, 1)",
                  fontWeight: "500",
                  fontSize: "1.2rem",
                  lineHeight: "1.8rem",
                  letterSpacing: "1%",
                }}
              >
                Email
              </InputLabel>
              <TextField
                fullWidth
                // label="Email"
                placeholder="name@example.com"
                variant="outlined"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={Boolean(errors.email)}
                helperText={errors.email}
                sx={{
                  background:
                    "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                  border: "1px solid rgba(222, 222, 222, 1)",
                  borderRadius: "0.8rem",
                  fontFamily: "Poppins,sans-serif",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "0.5rem",
                  fontFamily: "Poppins,sans-serif",
                  color: "rgba(137, 137, 137, 1)",
                  fontWeight: "500",
                  fontSize: "1.2rem",
                  lineHeight: "1.8rem",
                  letterSpacing: "1%",
                }}
              >
                Password
              </InputLabel>
              <TextField
                type="password"
                fullWidth
                // label="Password"
                placeholder="Enter your Password"
                variant="outlined"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={Boolean(errors.password)}
                helperText={errors.password}
                sx={{
                  background:
                    "linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
                  border: "1px solid rgba(222, 222, 222, 1)",
                  borderRadius: "0.8rem",
                  fontFamily: "Poppins,sans-serif",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  fullWidth
                  sx={{ fontSize: "12px" }}
                >
                  Forgot Password ?
                </Button>
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  onClick={signInWithEmailAndPasswordHandler}
                  fullWidth
                >
                  Sign In
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login1;
