export const images = {
  newAlerts: require("./newAlerts.png"),
  searchIcon: require("./search.png"),
  dropdownIcon: require("./dropdown.png"),
  criticalStatus: require("./Critical.png"),
  dispatchStatus: require("./Dispatch.png"),
  buttonGray: require("./ButtonGray.png"),
  buttonOrange: require("./ButtonOrange.png"),
  buttonCancel: require("./ButtonCancel.png"),
  buttonSaveGray: require("./ButtonSaveGray.png"),
  buttonSaveOrange: require("./ButtonSaveOrange.png"),
  store: require("./Store.png"),
  logo: require("./Logo.png"),
};
