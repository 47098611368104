import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { rulesConversion } from "../../api/rules_conversion";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { getAlert, updateEnabled, getEnabledValue } from "../../api/alerts";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import { auth } from "../../services/auth/firebaseapp";
import ConfirmationModal from "./ConfirmationModal";

export default function RulesCard() {
  const alert = useSelector((state) => state.alert);
  const [rule, setRule] = useState("");
  const [rawData, setRawData] = useState("");
  const [switchState, setSwitchState] = useState(false);
  const [username, setUsername] = useState("");
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  useEffect(() => {
    const fetchUser = async () => {
      const userName = auth.currentUser.email;
      console.log("user email", userName);
      if (userName) {
        setUsername(userName);
        if (
          userName === "kevin.weishaupt@swyft.com" ||
          userName === "loram@swyft.com" ||
          userName === "ankita@plugincoder.com"
        ) {
          console.log(userName);
          setIsSwitchDisabled(false);
        } else {
          setIsSwitchDisabled(true); // Disable switch for other usernames
        }
      }
    };

    fetchUser();

    getAlert(alert).then((conditions) => {
      if (conditions.length > 0) {
        setRawData(JSON.stringify(conditions, null, 2));
        setRule(rulesConversion(conditions));
      } else {
        setRawData("");
        setRule("");
      }
    });
    getEnabledValue(alert).then((enabledValue) => {
      setSwitchState(enabledValue === 1);
    });
  }, [alert]);

  const handleSwitchClick = () => {
    setIsModalOpen(true);
  };

  const handleYesEnableSwitch = () => {
    const newSwitchState = !switchState;
    setSwitchState(newSwitchState); // Update switch state
    updateEnabled(alert, newSwitchState ? 1 : 0); // Update backend state
    setIsModalOpen(false); // Close modal
  };

  const handleCancelEnableSwitch = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <Card sx={{ height: "100vh" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Alert Edit {alert}
        </Typography>
        <Item elevation={0}>
          <div className="chartTable_container">
            <div>
              <Grid>
                <h3>Enable / Disable Alert</h3>
                <Grid item xs={12}>
                  <Switch
                    {...label}
                    disabled={isSwitchDisabled}
                    onClick={handleSwitchClick} // Open modal on click
                    checked={switchState} // Control switch state
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Alert Rules - Breakdown
                      </Typography>
                      <Typography variant="body2">
                        <Box>
                          <div
                            style={{
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              fontSize: "14px",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                              }}
                            >
                              {rule}
                            </div>
                          </div>
                        </Box>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Alert Rules - raw json data
                      </Typography>
                      <Typography variant="body2">
                        <Box>
                          <div
                            style={{
                              height: "440px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              fontSize: "12px",
                            }}
                          >
                            <div>
                              <pre
                                style={{
                                  height: "440px",
                                  paddingTop: "8px",
                                  paddingBottom: "8px",
                                  fontSize: "12px",
                                  overflowY: "scroll", // Enable scrolling
                                }}
                              >
                                {rawData}
                              </pre>
                            </div>
                          </div>
                        </Box>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        </Item>
      </CardContent>

      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelEnableSwitch}
        onConfirm={handleYesEnableSwitch}
        switchState={switchState}
        alert={alert}
      />
    </Card>
  );
}
