import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { Buffer } from "buffer";
import { fetchTicketsDataFromAPI } from "../../api/gcp_alert";
import { EMAIL, PASSWORD } from "../../config/config";

const groupByIssueTask = (tickets) => {
  const groupedTickets = [];
  tickets.forEach((ticket) => {
    const issueTask = ticket.issueTask;
    // Find an existing group with the same ID
    const group = groupedTickets.find((group) => group.id === issueTask);

    if (group) {
      group.data.push(ticket);
    } else {
      // Create a new group
      groupedTickets.push({ id: issueTask, data: [ticket] });
    }
  });
  return groupedTickets;
};

const groupByIssueTaskapi = (tickets) => {
  const groupedTickets = [];
  tickets.forEach((ticket) => {
    const issueTask = ticket.task_issue;
    // Find an existing group with the same ID
    const group = groupedTickets.find((group) => group.id === issueTask);

    if (group) {
      group.data.push(ticket);
    } else {
      // Create a new group
      groupedTickets.push({ id: issueTask, data: [ticket] });
    }
  });
  return groupedTickets;
};

const selectID = (entities) => {
  if (Array.isArray(entities)) {
    return entities.map((entity) => selectID(entity));
  } else {
    return entities.ticketId;
  }
};

const selectKY = (entities) => {
  const keys = Object.keys(entities);
  if (keys.length === 2 && keys[0] === "") {
    return keys[1];
  } else {
    return undefined;
  }
};
export const ticketAdapter = createEntityAdapter({
  selectId: (entity) => entity.id,
  selectKey: selectKY,
});

const initialState = ticketAdapter.getInitialState({
  status: "idle",
  selectedData: [],
});

export const fetchTickets = createAsyncThunk(
  "tickets/fetchTickets",
  async (filters) => {
    let groupedData;
    try {
      const apiData = await fetchTicketsDataFromAPI(EMAIL, PASSWORD, filters);

      if (apiData.length > 0) {
        groupedData = groupByIssueTaskapi(apiData);
        console.log("data fromat Done");
      } else {
        groupedData = [];
      }
    } catch (error) {
      console.error(error);
    }
    return groupedData;
  }
);

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    ticketsReceived(state, action) {
      console.log("ticketsReceived action:", action);
      console.log("payload", action.payload);
    },
    groupTickets(state, action) {
      console.log("GP --state---", state.tickets);
    },
    selectedKey(state, action) {
      console.log("selectedkey", state.keySelected);
      console.log("selectedkey red", action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickets.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        ticketAdapter.setAll(state, action.payload);
        state.status = "fulfilled";
        console.log("fetch action executed");
        console.log("current status", action.requestStatus);
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export const { selectAll: selectTickets } = ticketsSlice.actions;
export const { ticketsReceived, selectedKey, groupTickets, selectTicket } =
  ticketsSlice.actions;
export default ticketsSlice.reducer;
