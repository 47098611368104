import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { images } from "../../res/images";
import "./CreateAlert.css";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import Pagination from "@mui/material/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { setKey } from "../../services/store/keySlice";
import { setAlert } from "../../services/store/alertSlice";
import { setPage } from "../../services/store/paginationSlice";
import { Loading } from "../../animation/Loading";
import { fetchRules } from "../../services/store/rulesSlice";

function CreateAlert() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [suggestedAlerts, setSuggestedAlerts] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedBoxId, setSelectedBoxId] = useState("");
  // const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [keyDescriptions, setKeyDescriptions] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const key = useSelector((state) => state.key);
  const rules = useSelector((state) => state.rules);
  const status = useSelector((state) => state.rules.status);
  const { page, itemsPerPage } = useSelector((state) => state.pagination); // Get the pagination state
  const [sortCriteria, setSortCriteria] = useState("alphabetical");

  useEffect(() => {
    if (status === "fulfilled" && rules.ids.length > 0) {
      const descriptions = {};
      rules.ids.forEach((id) => {
        descriptions[id] = rules.entities[id].description;
      });
      setKeyDescriptions(descriptions);
      setIsLoaded(true);
      setSelectedBoxId(key);
    } else if (status === "fulfilled" && rules.ids.length === 0) {
      setIsEmpty(true);
    }
  }, [status]);

  useEffect(() => {
    console.log("Current Page:", page);
  }, [page]);

  useEffect(() => {
    console.log("Selected Box ID:", selectedBoxId);
  }, [selectedBoxId]);

  const handleBoxClick = (issue, index) => {
    const alertString = String(index);
    console.log("handlebox", issue, index);
    dispatch(setAlert(alertString));
    dispatch(setKey(issue));
    setSelectedBoxId(issue);
  };

  const handleButtonClick = () => {
    navigate("/newalert");
  };

  const handleChangePage = (event, newPage) => {
    console.log("Page change:", newPage);
    dispatch(setPage(newPage)); // Update the page in Redux
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);

    if (searchValue !== "") {
      const suggested = Object.keys(keyDescriptions).filter((id) =>
        keyDescriptions[id].toLowerCase().includes(searchValue.toLowerCase())
      );
      setSuggestedAlerts(suggested);
      setIsDropdownOpen(true);
    } else {
      setSuggestedAlerts([]);
      setIsDropdownOpen(false);
    }
  };

  const handleSuggestionClick = (id) => {
    console.log("Suggestion ID:", id);
    console.log("Key Descriptions:", keyDescriptions);

    // Directly use the id to find the corresponding alert
    const selectedAlert = keyDescriptions[id];

    if (selectedAlert) {
      setSearchText("");
      setSuggestedAlerts([]);
      setSelectedAlerts([id, ...selectedAlerts]);
      setIsDropdownOpen(false);
      // Trigger the box click event
      handleBoxClick(selectedAlert, id);
    } else {
      console.error("No matching alert found.");
    }
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortData = (data) => {
    switch (sortCriteria) {
      case "alphabetical":
        return data.sort((a, b) =>
          keyDescriptions[a].localeCompare(keyDescriptions[b])
        );

      case "reverse":
        return data.reverse();
      default:
        return data;
    }
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const sortedData = sortData(Object.keys(keyDescriptions));
  const paginatedData = sortedData.slice(startIndex, endIndex);
  const pageCount = Math.ceil(sortedData.length / itemsPerPage);
  return (
    <Container>
      <Box className="main-container">
        {isLoaded ? (
          <Grid item className="container1">
            <section className="section1">
              <Box
                className="new_alerts_div1"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem",
                  gap: "1rem",
                }}
              >
                <div className="text_alerts">Alerts</div>
                <div className="btn">
                  <img
                    src={images.newAlerts}
                    alt="create button"
                    onClick={handleButtonClick}
                  />
                </div>
              </Box>

              <Box className="iconNseachbar">
                <div className="search_bar_space">
                  <TextField
                    className="search_bar_new"
                    id="search"
                    type="search"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    sx={{ width: "96%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className="search-icon"
                            src={images.searchIcon}
                            alt="Search icon"
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleDropdownClick}>
                            <img
                              className={`dropdown-icon${
                                isDropdownOpen ? " open" : ""
                              }`}
                              src={images.dropdownIcon}
                              alt="Dropdown icon"
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                {isDropdownOpen && (
                  <ul className="suggestions">
                    {suggestedAlerts.length > 0
                      ? suggestedAlerts.map((id) => (
                          <li
                            key={id}
                            onClick={() => handleSuggestionClick(id)}
                          >
                            {keyDescriptions[id]}
                          </li>
                        ))
                      : Object.keys(keyDescriptions).map((id) => (
                          <li
                            key={id}
                            onClick={() => handleSuggestionClick(id)}
                          >
                            {keyDescriptions[id]}
                          </li>
                        ))}
                  </ul>
                )}
              </Box>
            </section>

            {/* Sorting Dropdown */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "1.2rem",
                paddingRight: "1.2rem",
                gap: "1rem",
                margin: "1rem",
                marginTop: "1.2rem",
                borderRadius: "0.8rem",
                // backgroundColor: "#fff",
              }}
            >
              <FormControl
                variant="outlined"
                sx={{
                  width: "100%",
                  maxWidth: "350px",
                }}
              >
                <InputLabel id="sort-label">Sort By</InputLabel>
                <Select
                  labelId="sort-label"
                  id="sort"
                  value={sortCriteria}
                  onChange={handleSortChange}
                  label="Sort By"
                  sx={{
                    height: "2.5rem",
                    width: "100%",
                    maxWidth: "100%",
                  }}
                >
                  <MenuItem value="alphabetical">Alphabetical</MenuItem>
                  <MenuItem value="reverse">Reverse Order</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <section className="section2">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "1.2rem",
                  paddingRight: "1.2rem",
                  gap: "1rem",
                  margin: "1rem",
                  borderRadius: "0.8rem",
                  backgroundColor: "#fff",
                }}
              >
                <span className="text_all_alerts">All Issue Alerts</span>
                <span className="count_all_alerts">
                  {Object.keys(keyDescriptions).length}
                </span>
              </Box>

              <Box
                className="selected_alerts"
                sx={{
                  height: "300px",
                  overflowY: "auto",
                }}
              >
                {paginatedData.map((id, i) => (
                  <Box
                    key={id}
                    className={
                      keyDescriptions[id] === selectedBoxId ? "selected" : ""
                    }
                    onClick={() => handleBoxClick(keyDescriptions[id], id)}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      padding: "1.2rem",
                      margin: "1rem",
                      borderRadius: "0.8rem",
                      backgroundColor: "#fff",
                      border:
                        selectedBoxId === keyDescriptions[id]
                          ? "1px solid rgba(243, 110, 33, 1)"
                          : "none",
                    }}
                  >
                    <div>
                      <span className="alert_name">{keyDescriptions[id]}</span>
                    </div>
                    <div className="id_n_count_out">
                      <div className="id_n_count">
                        <div className="alert_id">
                          {rules.entities[id].alert_id}
                        </div>
                        <div className="alert_id_count">{""}</div>
                      </div>
                    </div>
                  </Box>
                ))}
                <section className="pagination-section">
                  <Stack spacing={2}>
                    <Pagination
                      count={pageCount}
                      page={page}
                      onChange={handleChangePage}
                      shape="rounded"
                      boundaryCount={2}
                      showFirstButton={page > 1}
                      showLastButton={page < pageCount}
                      className={"pagination__link"}
                      renderItem={(item) => {
                        switch (item.type) {
                          case "page":
                            if (
                              item.page === 1 ||
                              item.page === page ||
                              item.page === pageCount
                            ) {
                              return (
                                <PaginationItem
                                  {...item}
                                  style={{
                                    color: "rgba(243, 110, 33, 1)",
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              );
                            }
                            return null;
                          case "first":
                            return page === 1 ? (
                              <PaginationItem
                                {...item}
                                onClick={() => handleChangePage(null, 1)}
                              />
                            ) : null;
                          case "last":
                            return page === pageCount ? (
                              <PaginationItem
                                {...item}
                                onClick={() =>
                                  handleChangePage(null, pageCount)
                                }
                              />
                            ) : null;
                          default:
                            return <PaginationItem {...item} />;
                        }
                      }}
                    />
                  </Stack>
                </section>
              </Box>
            </section>
          </Grid>
        ) : (
          <>{isEmpty ? <>No Data</> : <Loading />}</>
        )}
      </Box>
    </Container>
  );
}

export default CreateAlert;
