// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need

import {initializeApp} from "firebase/app";
import {firebaseConfig} from '../../config/config';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup  } from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
  } from "firebase/firestore";
  import { getDatabase, ref, onValue } from "firebase/database";
  
const firebaseapp = initializeApp(firebaseConfig);

const dbs = getFirestore(firebaseapp);

const db = getDatabase(firebaseapp)
const googleProvider = new GoogleAuthProvider();
const auth = getAuth(firebaseapp);

const signInWithGoogle = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(dbs, 'users'), where('uid', '==', user.uid));
        const docs = await getDocs(q);
  
        if (docs.docs.length > 0) {
          console.log('Successfully logged in:', user.displayName);
          resolve(true);
        } else {
          // User doesn't exist in Firestore, handle accordingly
          console.log('User not found in Firestore');
          // You may want to sign out the user or show an error message
          resolve(false);
        }
      } catch (err) {
        console.error(err);
        alert(err.message);
        reject(err);
      }
    });
  };


export const user = getAuth().currentUser;
export { auth, onAuthStateChanged, db ,signInWithGoogle };
export default firebaseapp;