import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "../Chart/chart";
import CreateTable from "../Table/CreateTable";
import "./dashboard.css";
import CreateAlert from "../Alerts/CreateAlert";
import { useSelector, useDispatch } from "react-redux";
import { lastRun, getAlert } from "../../api/alerts";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { fetchTickets } from "../../services/store/ticketsSlice";
import { fetchRules } from "../../services/store/rulesSlice";
import { getConcepts } from "../../api/alerts";
import { setVendor, setConcepts } from "../../services/store/vendorSlice";

function Newdash() {
  const [selectedData, setSelectedData] = useState([]);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const key = useSelector((state) => state.key);
  const groupedTickets = useSelector((state) => state.tickets);
  const status = useSelector((state) => state.tickets.status);
  const time = useSelector((state) => state.time);
  const vendor = useSelector((state) => state.vendor.vendor);
  const speciality = useSelector((state) => state.speciality);
  const range = useSelector((state) => state.range);
  const conceptsData = useSelector((state) => state.vendor.concepts);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  useEffect(() => {
    dispatch(fetchRules());
  }, []);

  useEffect(() => {
    getConcepts().then((concepts) => {
      console.log("Fetched concepts:", concepts);
      dispatch(setConcepts(concepts));
    });
  }, []);

  useEffect(() => {
    const filters = [time, vendor, speciality];
    dispatch(fetchTickets(filters));
    console.log("filters date", time);
    console.log("filters vendor", vendor);
    console.log("filters speciality", speciality);
  }, [time, vendor, speciality]);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={4}>
            <Item elevation={0}>
              <CreateAlert />
            </Item>
          </Grid>
          <Grid item xs={8}>
            <Item elevation={0}>
              <div className="chartTable_container">
                <Chart />
                <CreateTable />
              </div>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Newdash;
