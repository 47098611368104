import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { auth } from "./services/auth/firebaseapp";
import PrivateRoute from "./services/privaterouter/privaterouter";
import Checkbox from "@mui/material/Checkbox";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/loginform";
import Home from "./components/home";
import NewAlertPage from "./components/NewAlertPage/NewAlertPage";
import Navbar from "./components/Navbar/Navbar";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "./components/Navbar/sidebar";
import { onAuthStateChanged } from "firebase/auth";
import NmsLogsTable from "./components/NmsLogs/nmsLogs";
import AlertRules from "./components/Alerts/alertRules";
import { styled } from "@mui/material/styles";
import Login1 from "./components/loginform2";

const mainTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#F36E21",
    },
    lightorange: {
      main: "#FFF2EB",
    },
    red: {
      main: "#F41E1E",
    },
    lightred: {
      main: "#FFEDED",
    },
    yellow: {
      main: "#F4C006",
    },
    lightyellow: {
      main: "#FFF8E1",
    },
    blue: {
      main: "#50BCF9",
    },
    lightblue: {
      main: "#E7F6FF",
    },
    voilet: {
      main: "#9050F9",
    },
    lightvoilet: {
      main: "#F1E9FF",
    },
    indigo: {
      main: "#5061F9",
    },
    lightindigo: {
      main: "#E9EBFF",
    },
    gray: {
      main: "#B1B1B1",
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const Div = styled("div")(({ theme }) => ({
    ...theme.typography.h4,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    marginLeft: "30px",
  }));

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={mainTheme}>
          {user && (
            <SideBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
          )}

          <div
            className={`container ${
              drawerOpen ? "drawer-open" : "drawer-closed"
            }`}
          >
            <Routes>
              <Route path="/login" element={<Login1 />} />
              <Route exact path="/" element={<PrivateRoute />}>
                <Route
                  exact
                  path="/"
                  element={
                    <>
                      <Home />
                    </>
                  }
                />
              </Route>
              <Route exact path="/dashboard" element={<PrivateRoute />}>
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <>
                      <Dashboard />
                    </>
                  }
                />
              </Route>
              <Route exact path="/editalert" element={<PrivateRoute />}>
                <Route
                  exact
                  path="/editalert"
                  element={
                    <>
                      <AlertRules />
                    </>
                  }
                />
              </Route>
              <Route exact path="/newalert" element={<PrivateRoute />}>
                <Route
                  exact
                  path="/newalert"
                  element={
                    <>
                      <NewAlertPage />
                    </>
                  }
                />
              </Route>
              <Route exact path="/nmslogs" element={<PrivateRoute />}>
                <Route
                  exact
                  path="/nmslogs"
                  element={
                    <>
                      <NmsLogsTable />
                    </>
                  }
                />
              </Route>
            </Routes>
          </div>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
