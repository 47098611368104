import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import CreateAlert from "./CreateAlert";
import "./rules.css";
import { fetchRules } from "../../services/store/rulesSlice";
import Switch from "@mui/material/Switch";
import RulesCard from "./alertCard";

export default function AlertRules() {
  const [value, setValue] = useState(0);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const rules = useSelector((state) => state.rules);
  const status = useSelector((state) => state.rules.status);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  useEffect(() => {
    dispatch(fetchRules());
  }, []);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={4}>
            <Item elevation={0}>
              <CreateAlert />
            </Item>
          </Grid>
          <Grid item xs={8}>
            <RulesCard />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
