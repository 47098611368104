import * as React from "react";
import { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box, TextField, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setStoreId } from "../../services/store/storeIdSlice";
import { fetchLogs } from "../../services/store/logsSlice";
import { setNmsRange } from "../../services/store/rangeSlice";
import "./nmsLogs.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function MultipleSelectCheckmarks() {
  const dispatch = useDispatch();
  const range = useSelector((state) => state.range);
  const dashFilters = useSelector((state) => state.filters);

  const today = new Date();
  const last30Days = new Date(today);
  last30Days.setDate(today.getDate() - 30);

  const last15Days = new Date(today);
  last15Days.setDate(today.getDate() - 15);

  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [filterDate, setFilterDate] = useState("");
  const [selectedStores, setSelectedStores] = React.useState([]);
  const names = dashFilters.ids;

  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const date = [
    { value: formatDate(last30Days), label: "Last 30 Days" },
    { value: formatDate(last15Days), label: "Last 15 Days" },
    { value: formatDate(lastWeek), label: "Last Week" },
    { value: formatDate(yesterday), label: "Yesterday" },
  ];

  useEffect(() => {
    setFilterDate(range);
    if (selectedStores.length === 0 || selectedStores === "all") {
      dispatch(setStoreId("all"));
    } else if (selectedStores.length === names.length) {
      dispatch(setStoreId("all"));
    } else {
      dispatch(setStoreId(selectedStores));
    }
  }, [selectedStores, names.length]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let newSelectedStores;

    if (value.includes("all")) {
      if (selectedStores.length === names.length || selectedStores === "all") {
        newSelectedStores = []; // Unselect everything
      } else {
        newSelectedStores = names; // Select all
      }
    } else {
      newSelectedStores = typeof value === "string" ? value.split(",") : value;
    }

    setSelectedStores(
      newSelectedStores.length === names.length ? "all" : newSelectedStores
    );
  };

  const isAllSelected =
    selectedStores === "all" || selectedStores.length === names.length;

  const handleApplyFilters = () => {
    let filters = [];

    if (selectedStores === "all") {
      filters = [filterDate, "all"];
      console.log("Fetching all logs...", filters);
      dispatch(fetchLogs(filters));
    } else {
      const storesString = selectedStores.join(",");
      filters = [filterDate, storesString];
      console.log("Fetching logs for selected stores:", filters);
      dispatch(fetchLogs(filters));
    }
  };

  const handleDate = (date) => {
    console.log("Selected date:", date);
    setFilterDate(date);
    dispatch(setNmsRange(date));
  };

  return (
    <div className="filter-container">
      <Box>
        <TextField
          select
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "1.4rem",
              fontWeight: "400",
              color: "rgba(137, 137, 137, 1)",
            },
          }}
          InputProps={{
            disableanimation: "true",
            style: {
              borderBottom: "none",
            },
          }}
          sx={{
            background:
              "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #DEDEDE, #DEDEDE)",
            border: "1px solid rgba(222, 222, 222, 1)",
            borderRadius: "0.8rem",
            fontFamily: "Poppins,sans-serif",
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
          value={filterDate}
          onChange={(e) => handleDate(e.target.value)}
        >
          {date.map((option) => (
            <MenuItem defaultValue="" key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <FormControl sx={{ width: "300px", marginLeft: "10px" }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedStores === "all" ? [] : selectedStores}
          onChange={handleChange}
          input={<OutlinedInput label="Store ID" />}
          renderValue={(selected) =>
            selected.length ? selected.join(", ") : "All"
          }
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selectedStores.length > 0 &&
                selectedStores.length < names.length
              }
            />
            <ListItemText primary="Select All" />
          </MenuItem>
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox
                checked={
                  selectedStores.indexOf(name) > -1 || selectedStores === "all"
                }
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleApplyFilters}
        style={{
          marginLeft: "10px",
          height: "50px",
          color: "#fff",
          fontSize: "12px",
        }}
      >
        Apply
      </Button>
    </div>
  );
}
