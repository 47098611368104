import { configureStore } from "@reduxjs/toolkit";
import ticketsReducer from "./ticketsSlice";
import keyReducer from "./keySlice";
import timeReducer from "./timeSlice";
import specialityReducer from "./specialitySlice";
import vendorReducer from "./vendorSlice";
import alertReducer from "./alertSlice";
import logsReducer from "./logsSlice";
import rangeReducer from "./rangeSlice";
import userReducer from "./userSlice";
import filterReducer from "./filtersSlice";
import storeIdReducer from "./storeIdSlice";
import rulesReducer from "./rulesSlice";
import paginationReducer from "./paginationSlice";

export default configureStore({
  reducer: {
    tickets: ticketsReducer,
    key: keyReducer,
    speciality: specialityReducer,
    time: timeReducer,
    vendor: vendorReducer,
    alert: alertReducer,
    logs: logsReducer,
    range: rangeReducer,
    user: userReducer,
    filters: filterReducer,
    storeId: storeIdReducer,
    rules: rulesReducer,
    pagination: paginationReducer,
  },
});
