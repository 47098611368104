import React, { useState } from "react";
import { Button, Container, Grid, Paper, Typography, Box } from "@mui/material";
import { styled, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { setUser } from "../services/store/userSlice";

const mainTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#F36E21",
    },
    lightorange: {
      main: "#FFF2EB",
    },
    red: {
      main: "#F41E1E",
    },
    lightred: {
      main: "#FFEDED",
    },
    yellow: {
      main: "#F4C006",
    },
    lightyellow: {
      main: "#FFF8E1",
    },
    blue: {
      main: "#50BCF9",
    },
    lightblue: {
      main: "#E7F6FF",
    },
    voilet: {
      main: "#9050F9",
    },
    lightvoilet: {
      main: "#F1E9FF",
    },
    indigo: {
      main: "#5061F9",
    },
    lightindigo: {
      main: "#E9EBFF",
    },
    gray: {
      main: "#B1B1B1",
    },
  },
});

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.h4,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    textAlign: "left",
    padding: "6px",
  }));

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: "", password: "" };

    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const signInWithEmailAndPasswordHandler = async () => {
    if (!validateForm()) {
      return console.log("ERROR: Fields Empty");
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userName = userCredential.user.displayName;
      dispatch(setUser(userName));
      navigate("/dashboard");
    } catch (error) {
      console.error(
        "Sign-in error:",
        error.message || "Unknown error occurred"
      );
    }
  };

  const signInWithGoogleHandler = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const userName = result.user.displayName;
      dispatch(setUser(userName));
      navigate("/dashboard");
    } catch (error) {
      console.error(
        "Google sign-in error:",
        error.message || "Unknown error occurred"
      );
    }
  };

  const CustomButton = styled(Button)(({ theme }) => ({
    fontSize: "15px",
    padding: "10px 20px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    textTransform: "none",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    margin: "5px", // Ensure consistent margin
    borderRadius: "4px",
  }));

  return (
    <Box>
      <Container>
        <Grid
          sx={{ mx: "auto" }}
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid m={2} container spacing={2}>
            <Grid item xs={12}>
              {/* <Item elevation={0}>Sign In</Item> */}
              <Div sx={{ textAlign: "center" }}>{"Sign In"}</Div>
            </Grid>
            {/* <Grid item xs={12}>
              <Item>
                <Button
                  variant="contained"
                  color="primary"
                  elevation={1}
                  onClick={signInWithGoogleHandler}
                >
                  Sign In With Google
                </Button>
              </Item>
            </Grid> */}
            <Grid item xs={12}>
              <Box sx={{ maxWidth: "100%" }}>
                <TextField
                  fullWidth
                  label="Email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ maxWidth: "100%" }}>
                <TextField
                  type="password"
                  fullWidth
                  label="Password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                />
              </Box>
            </Grid>
            {/* <Grid item xs={8}>
              <Item elevation={0}>
                <Button sx={{ fontSize: "15px" }}>{"forgot password"}</Button>
              </Item>
            </Grid> */}
            <Grid item xs={4}>
              <Item sx={{ textAlign: "center" }}>
                <CustomButton
                  onClick={signInWithEmailAndPasswordHandler}
                  sx={{ fontSize: "15px" }}
                >
                  Sign in
                </CustomButton>
              </Item>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Item sx={{ textAlign: "center" }}>
                <CustomButton
                  variant="contained"
                  // color="primary"
                  // elevation={1}
                  onClick={signInWithGoogleHandler}
                >
                  Sign In With Google
                </CustomButton>
              </Item>
            </Grid>

            <Grid item xs={8} lg={4}>
              <Item sx={{ textAlign: "center" }}>
                <CustomButton sx={{ fontSize: "15px" }}>
                  Forgot password
                </CustomButton>
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;

// useEffect(() => {
//   const currentUrl = window.location.href;
//   const urlParams = new URLSearchParams(window.location.search);
//   const tokenFromUrl = urlParams.get('token');
//   const urlWithoutToken = currentUrl.replace(/(\?|&)token=[^&]*(&|$)/, '$1');
//   printMsg();
//   window.history.replaceState({}, document.title, urlWithoutToken);
//   if (tokenFromUrl) {
//         signInWithToken(auth,tokenFromUrl,'http://localhost:3001/verify-token',signInWithCustomToken)
//         .then((customToken) => {
//         console.log('Signed in with custom token:', customToken);
//         navigate('/dashboard');
//       })
//       .catch((error) => {
//         console.error('Token sign-in error:', error);
//         // Handle the error
//       });
//   }
// }, []);
