function convertToEnglish(array) {
    let summary = [];

    array.forEach(obj => {
        const outerKey = Object.keys(obj)[0];
        const innerObj = obj[outerKey];
        const innerKey = Object.keys(innerObj)[0];
        const innerValue = innerObj[innerKey];

        let result = `For ${outerKey}, ${innerKey} is`;

        if (typeof innerValue === 'object') {
            const operator = Object.keys(innerValue)[0];
            const operand = innerValue[operator];
            result += ` ${operator} ${operand}`;
        } else {
            result += ` "${innerValue}"`;
        }

        summary.push(result);
    });

    return summary.map(item => `• ${item}`).join('\n');
}

export const rulesConversion = (conditions) => {
    const general = [];
    const alert = [];
    const event = [];
    const sales = [];
    const state_change = [];
    const chronic_monthly = [];
    const chronic_bi_weekly = [];

    conditions.forEach(conditionSet => {
        conditionSet.forEach(condition => {
            const outermostKey = Object.keys(condition)[0];
            const innerCondition = condition[outermostKey];

            switch (outermostKey) {
                case 'general':
                    general.push(innerCondition);
                    break;
                case 'alert':
                    alert.push(innerCondition);
                    break;
                case 'event':
                    event.push(innerCondition);
                    break;
                case 'sales':
                    sales.push(innerCondition);
                    break;
                case 'state_change':
                    state_change.push(innerCondition);
                    break;
                case 'chronic_monthly':
                    chronic_monthly.push(innerCondition);
                    break;
                case 'chronic_bi_weekly':
                    chronic_bi_weekly.push(innerCondition);
                    break;
                default:
                    // Handle other cases or log an error if needed
                    break;
            }
        });
    });

    // Print a single sentence summarizing the information
    const summary = convertToEnglish([
        ...general,
        ...alert,
        ...event,
        ...sales,
        ...state_change,
        ...chronic_monthly,
        ...chronic_bi_weekly
    ]);

    return summary;
};