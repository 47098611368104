import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendor: "all",
  concepts: [],
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setVendor: (state, action) => {
      console.log("vendor set", action.payload); //ZOOM_CVS_US
      state.vendor = action.payload;
    },
    setConcepts: (state, action) => {
      console.log("concepts set", action.payload);
      state.concepts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => state);
  },
});

export const { setVendor, setConcepts } = vendorSlice.actions;
export default vendorSlice.reducer;